/* COPYRIGHT PAUL IONESCU - ALL RIGHTS RESERVED */

import React from 'react'
import NewsProvider from './NewsProvider'
import { Card, Spinner } from 'react-bootstrap'
import NewsArticle from './NewsArticle'

class NewsPage extends React.Component {

    MAX_LATEST = 5

    provider = null
    lang = 'en'

    constructor(props){
        super(props)
        if(this.props.lang) this.lang = this.props.lang

        this.provider = new NewsProvider(this.lang)
        this.msg = window.mciTranslations

        this.state = {
            latestNews : null,
            olderNews : null,
            articleText : null,
            articleTitle : null,
            articleDate : null
        }
    }

    componentDidMount = async() => {

        if(!this.provider) return

        if(!this.props.newsId) return


        await this.provider.loadNews()
        if(!this.provider.news) return

        try {
            if(this.props.newsId === 'home'){
                await this.loadNewsHome()
            }
            else{
                await this.loadNewsItem(this.props.newsId)
            }
        } 
        catch (error) {
            console.log(error)
        }
        
    }

    loadNewsItem = async(newsId) => {
        let newsIndex = this.provider.news
        let article;
        //find the id
        for(let newsEntry of newsIndex){
            let curId = newsEntry.title.replace(/[^a-z0-9]/gi,'-')
            if(curId === newsId){ //item found
                article = new NewsArticle(newsEntry, this.lang)
                await article.load()
                break
            }
        }
        this.setState({article:article})
    }


    loadNewsHome = async() => {
        let latestNewsIndex = this.provider.news.slice(0,this.MAX_LATEST)
        let latestNews = []
        for(let newsEntry of latestNewsIndex){
            let cardInfo = newsEntry
            let article = new NewsArticle(newsEntry, this.lang);
            await article.load()
            if(!article.error){
                cardInfo.description = article.summary
                cardInfo.id = newsEntry.title.replace(/[^a-z0-9]/gi,'-')

                latestNews.push(cardInfo)
            }
        }

        let olderNews = []
        if(this.provider.news.length > this.MAX_LATEST){
            olderNews = this.provider.news.slice(this.MAX_LATEST)
        }

        this.setState({latestNews:latestNews, olderNews:olderNews})

    }

    render(){
        if(!window.mciTranslations || !this.provider.news) return <></>
        let article = this.state.article
        let markup = <></>
        let latestNewsMarkup = <></>
        let olderNewsMarkup = <></>
        if(this.props.newsId === 'home'){
            if(!this.state.latestNews) return <Spinner animation="grow" />
            else {
                latestNewsMarkup = this.state.latestNews.map((entry,index)=>{
                return <span key={`latest-news-${index}`}>
                    <Card  className='my-2'>
                        <Card.Body>
                            <a href={`/${this.lang}/news/${entry.id}`}>
                                <Card.Title>
                                    {entry.title}
                                </Card.Title>
                            </a>
                            <Card.Subtitle>
                                    {entry.date.toLocaleDateString()}
                                </Card.Subtitle>
                            <Card.Text>
                                {entry.description}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </span>
                })
            }

            if(this.state.olderNews && this.state.olderNews.length > 0){
                olderNewsMarkup = this.state.olderNews.map((entry,index)=>{
                    let newsId = entry.title.replace(/[^a-z0-9]/gi,'-')

                    return <span key={`older-news-${index}`}>
                        <li>
                            <a href={`/${this.lang}/news/${newsId}`}>
                                {entry.date.toLocaleDateString()} - {entry.title}
                            </a>
                        </li>
                    </span>
                })
                olderNewsMarkup = <>
                    <hr></hr>
                    <h5>{this.msg.OlderNews[this.lang]}</h5>
                    <ul>{olderNewsMarkup}</ul>
                </>
            }


            markup = <>
                {latestNewsMarkup}
                {olderNewsMarkup}
            </>
        }
        else if(article){
            
            if(article.title){
                document.title = `${article.title} | DG Immigration Consultants`
            }
            else{
                document.title = this.msg.NotFound[this.lang];
            }
            markup = <>
                <h3>{article.title}</h3>
                <h5>{article.date}</h5>
                <div dangerouslySetInnerHTML={{__html:article.html}}></div>
            </>
        }

        return (
            <>{markup}</>
        )
    }

}
  
export default NewsPage
  