/* COPYRIGHT PAUL IONESCU - ALL RIGHTS RESERVED */

import en from "./img/en.png";
import ro from "./img/ro.png";
import fr from "./img/fr.png";
import React from "react";

class LangSelector extends React.Component {
    render(){
      let pageId = "home";
      let pageType = "pages";
      if(this.props.pageId){
        pageId = this.props.pageId;
      }
      if(this.props.pageType){
        pageType = this.props.pageType;
        if(pageType === "news") pageId = "home"
      }
      return (
          <p className="text-right">
              <a href={`/en/${pageType}/${pageId}`} data-testid="en" className="mr-1">
                <img alt="English language" src={en}></img>
              </a>
              <a href={`/ro/${pageType}/${pageId}`} data-testid="ro" className="mr-1">
                <img alt="Limba română" src={ro}></img>
              </a>
              <a href={`/fr/${pageType}/${pageId}`} data-testid="fr" className="mr-1">
                <img alt="Le langue Français" src={fr}></img>
              </a>
            </p>    
      );
    }

}
  
export default LangSelector;
  