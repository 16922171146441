/* COPYRIGHT PAUL IONESCU - ALL RIGHTS RESERVED */

import YamlLoader from "./YamlLoader";

class NewsProvider {

    news = [];
    lang = "en";
    
    constructor(langArg){
        this.lang = langArg;
    }

    loadNews = async() => {
        let yamlLoader = new YamlLoader();
        this.news = await yamlLoader.load(`/content/news/${this.lang}/news-index.yml`);
    }



}
  
export default NewsProvider;
  