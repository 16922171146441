/* COPYRIGHT PAUL IONESCU - ALL RIGHTS RESERVED */

import React from "react";
import NewsProvider from "./NewsProvider";
import {Button} from "react-bootstrap";

class NewsSidebar extends React.Component {

    provider = null;
    lang = "en";

    constructor(props){
        super(props);
        if(this.props.lang) this.lang = this.props.lang;
        if(this.lang){
            this.provider = new NewsProvider(this.lang);
        }

        this.state = {
            news : null
        }
    }


    componentDidMount = async() => {

        if(this.provider){
            await this.provider.loadNews();
            if(this.provider.news) this.setState({news:this.provider.news});
        }
    }

    render(){
        let news = this.state.news;
        if(!window.mciTranslations || !news) return <></>
        let msg = window.mciTranslations;
        //display only the first 5
        news = news.slice(0,5);

        const markup = news.map((newsItem, index) => {
            if(!newsItem) return <></>;
            let title = newsItem.title;
            if(!title) return <></>;
            let newsId = title.replace(/[^a-z0-9]/gi,"-");
            return <span key={`news-${index}`}>
                <p>
                    <a href={`/${this.props.lang}/news/${newsId}`}>{title}</a>
                </p>
            </span>
            }
        );

        return <>
                <h3>{msg.LatestNews[this.lang]}</h3>
                {markup}
                <Button variant="outline-primary" href={`/${this.lang}/news/home`}>{msg.ReadMore[this.lang]}</Button>
            </>
    }

}
  
export default NewsSidebar;
  