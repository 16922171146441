import mammoth from 'mammoth'
import md from 'markdown-it'

class NewsArticle{

    MIN_DESCRIPTION = 255
    
    title
    summary
    date
    file
    type //MD|DOCX|UNKNOWN
    html
    lang
    error

    constructor(newsEntry, langArg){
        this.lang = langArg
        this.title = newsEntry.title
        this.date = newsEntry.date.toLocaleDateString()
        this.file = newsEntry.file
        let lFile = this.file.toLowerCase()
        if(lFile.endsWith('.md')){
            this.type = 'MD'
        }
        else if(lFile.endsWith('.docx')){
            this.type = 'DOCX'
        }
        else{
            this.type = 'UNKOWN'
        }
    }

    parseMD = async(response) =>{
        let mdText = await response.text()
        const parser = md({
            html: true,
            linkify: true,
            typographer: true
          })
        this.html = parser.render(mdText)
        let rawText = mdText.replace(/[`#*]/g,'')
        this.summary = this.extractSummary(rawText)
    }

    parseDOCX = async(response) =>{
        let buffer = await response.arrayBuffer()
        let result = await mammoth.convertToHtml({arrayBuffer:buffer})
        this.html = result.value;

        result = await mammoth.extractRawText({arrayBuffer:buffer})
        this.summary = this.extractSummary(result.value)
    }

    extractSummary = (text) => {
        text = text.replace(/^#.*$/,'')
        if(text.length > this.MIN_DESCRIPTION){
            let firstNL = text.indexOf('\n', this.MIN_DESCRIPTION);
            if(firstNL > -1){
                text = text.substr(0, firstNL) + '..'
            }
        }
        return text
    }

    load = async() => {
        try {
            let response = await fetch(`/content/news/${this.lang}/${this.file}`);
            if(response.status === 200){
                if(this.type === 'MD'){
                    await this.parseMD(response)
                }
                if(this.type === 'DOCX'){
                    await this.parseDOCX(response)
                }
            }
        } catch (error) {
           console.error(error)
           this.error = error 
        }
        
    }
}
export default NewsArticle