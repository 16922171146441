/* COPYRIGHT PAUL IONESCU - ALL RIGHTS RESERVED */

import './App.css';
import HomePage from './HomePage';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';


function App() {
  return (
    <Router>
      <Route path="/" exact={true} component={ChooseLanguage}></Route>
      
      <Switch>
        <Route path="/:lang/:pageType/:pageId" children={(args) => { return <HomePage routerArgs={args} />}} />
        <Route exact path="/:lang" children={(args) => { 
          let lang = args.match.params.lang || "en"
          return <Redirect to={`/${lang}/pages/home`} />
        }} />
        <Route exact path="/:lang/:pageId" children={(args) => { 
          let lang = args.match.params.lang || "en"
          let pageId = args.match.params.pageId || "pages/home"
          if(pageId === "about-us") pageId = "pages/about"
          else if(pageId === "servicii") pageId = "pages/services"
          else if(pageId === "contact") pageId = "pages/contact"
          else if(pageId === "chestionare") pageId = "pages/assessment"
          else if(pageId === "noutati" || pageId === "news") pageId = "news/home"
          else pageId = "pages/home"
          return <Redirect to={`/${lang}/${pageId}`} />
        }} />
        <Route>
          <Redirect to="/en/pages/home" /> 
        </Route>
        
      </Switch>    
      </Router>
  );
}

function ChooseLanguage(){
  let browserLanguage = (window.navigator.userLanguage || window.navigator.language);
  let language = "en";
  if(browserLanguage && browserLanguage.length >= 2){
    browserLanguage = browserLanguage.substr(0,2);
    let rx = new RegExp(/[a-z]/);
    if(browserLanguage.match(rx)){
      language = browserLanguage;
    }
  }
  window.location = `/${language}/pages/home`;

  return <></>
}

export default App;
