/* COPYRIGHT PAUL IONESCU - ALL RIGHTS RESERVED */

import React from "react";
import { Container, Jumbotron, Navbar, Nav, Image, Row, Col} from 'react-bootstrap';
import BasePage from './BasePage';
import NewsPage from "./NewsPage";
import NewsSidebar from "./NewsSidebar";
import LangSelector from "./LangSelector";
import banner from "./img/banner-new.jpg";
import crcic from "./img/rcic.jpg";
import YamlLoader from "./YamlLoader";

class HomePage extends React.Component {

  constructor(props) {
    super();
    this.state = {
      messagesLoaded : false
    }
  }

  componentDidMount = async() => {
    let loader = new YamlLoader();
    let messagesYaml = await loader.load("/content/translations.yml");
    if(messagesYaml){ 
      //save the messages in the window for other components
      window.mciTranslations = messagesYaml;
      this.setState({messagesLoaded:true}); 
    }
  }

  render(){
    if(!this.state.messagesLoaded) return <></>;

    let lang = "en", pageType = "pages", pageId = "home";
    let msg = window.mciTranslations;


    if(this.props.routerArgs && this.props.routerArgs.match){
      lang = this.props.routerArgs.match.params.lang;
      pageType = this.props.routerArgs.match.params.pageType;
      pageId = this.props.routerArgs.match.params.pageId;
    }
    if(pageType && pageId){
      let title = "DG Immigration Consultants";
      if(pageType === "news"){
        title = `${msg.News[lang]} | ${title}`;
      }
      else{
        switch(pageId){
          case "about": title = `${msg.About[lang]} | ${title}`; break;
          case "assessment": title = `${msg.Assessment[lang]} | ${title}`; break;
          case "services": title = `${msg.Services[lang]} | ${title}`; break;
          case "contact": title = `${msg.Contact[lang]} | ${title}`; break;
          default: pageId = "home"; break;
        }
      }

      document.title = title;
    }

    return (
        <>
        <Container className="border px-0 border-1">
          <Jumbotron id="page-top" className="my-0 py-2 App-header-footer">
            <LangSelector pageType={pageType} pageId={pageId}></LangSelector>
            <h1>DG Immigration Consultants</h1>
          </Jumbotron>
          <Image alt="Page banner" src={banner} fluid></Image>
          <Navbar collapseOnSelect={true} variant="dark" bg="dark"
            className="text-uppercase py-1" sticky="top" expand="lg">
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav>
                <Nav.Link href={`/${lang}/pages/home`}>{msg.Home[lang]}</Nav.Link>
                <Nav.Link href={`/${lang}/news/home`}>{msg.News[lang]}</Nav.Link>
                <Nav.Link href={`/${lang}/pages/about`}>{msg.About[lang]}</Nav.Link>
                <Nav.Link href={`/${lang}/pages/assessment`}>{msg.Assessment[lang]}</Nav.Link>
                <Nav.Link href={`/${lang}/pages/services`}>{msg.Services[lang]}</Nav.Link>
                <Nav.Link href={`/${lang}/pages/contact`}>{msg.Contact[lang]}</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>

          <Row className="m-0">
            <Col sm={9} className="p-4 h-100 bg-light">
              {pageType === "news" && (<NewsPage lang={lang} newsId={pageId} ></NewsPage>)}
              {pageType !== "news" && (<BasePage lang={lang} pageId={pageId}></BasePage>)}
            </Col>
            <Col className="p-4 h-100">
              <NewsSidebar lang={lang}></NewsSidebar>
            </Col>
          </Row>

          <footer className="p-3 text-secondary App-header-footer">
            <Row>
              <Col sm={3}>
                <a href="https://college-ic.ca/">
                <Image alt="CRCIC Logo" src={crcic} fluid></Image>
                </a>
              </Col>
              <Col sm={4}>
                <h4>{msg.RegulatedConsultant[lang]}</h4>
              </Col>
              <Col sm={4}>
                <h5>
                  DG Immigration Consultants
                </h5>
                <p>343 Preston Street, 11th Floor, Ottawa, ON., Canada, K1S 1N4</p>
              </Col>
            </Row>
          </footer>
          
        </Container>
        
      </>

        
      );
  }
}

export default HomePage;
